import {Injectable} from '@angular/core';
import {ApiClientService} from '../core/api-client.service';
import {CachePriority} from '../core/enums/cache-priority.enum';
import {Observable} from 'rxjs';
import {HttpAction} from '../core/enums/http-action.enum';
import {ApiClientOptions} from '../core/models/api-client-options';
import {AcademicYearResponse} from '../models/academic-year-response';
import {AcademicYearModel} from '../core/models/academic-year-model';
import {SchoolAcademicYearResponse} from '../core/models/school-academic-year-response';
import {SortService} from '../services/sort.service';

@Injectable({
  providedIn: 'root'
})
export class AcademicYearService {

    constructor(
        private apiClient: ApiClientService,
        private _sortService: SortService) {
    }

    getAcademicYears(cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<AcademicYearResponse[]> {
        let getURL = "api/AcademicYears";
        return this.apiClient.InvokeApi<AcademicYearResponse[]>(getURL, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    getAcademicYearById(academicYearId: string, schoolId: string = null, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<AcademicYearResponse> {
        let getURL = `api/AcademicYears/${academicYearId}`;
        const options: ApiClientOptions = new ApiClientOptions(true, cacheLevel);
        if (schoolId) {
            options.schoolIdHeader = schoolId;
        }
        return this.apiClient.InvokeApi<AcademicYearResponse>(getURL, HttpAction.Get, null, options);
    }

    getCurrentAcademicYear(schoolId: string, sendSchoolId: boolean = false, cacheLevel: CachePriority = CachePriority.NetworkFirst)
                            : Promise<AcademicYearModel> {
        const getURL = `api/Schools/${schoolId}/AcademicYears/Current`;
        const options: ApiClientOptions = new ApiClientOptions(true, cacheLevel);
        if (sendSchoolId) {
            options.schoolIdHeader = schoolId;
        }
        return this.apiClient.InvokeApi<AcademicYearModel>(getURL, HttpAction.Get, null, options).toPromise();
    }

    getSchoolAcademicYearBySchoolId(schoolId: string, academicYearId: string, sendSchoolId: boolean = false)
                                    : Promise<SchoolAcademicYearResponse> {
        const getURL = `api/Schools/${schoolId}/AcademicYears/${academicYearId}`;
        const options: ApiClientOptions = new ApiClientOptions();
        if (sendSchoolId) {
            options.schoolIdHeader = schoolId;
        }
        return this.apiClient.InvokeApi<SchoolAcademicYearResponse>(getURL, HttpAction.Get, null, options).toPromise();
    }

    async getAcademicYearInfosByStudent(studentId: string, excludeFutureClasses: boolean = false): Promise<AcademicYearResponse[]> {
        const academicYears = await this.apiClient.Get<AcademicYearResponse[]>(`api/Students/${studentId}/AcademicYears?excludeFutureClasses=${excludeFutureClasses}`);
        return this._sortService.sortDateString(academicYears, 'startDate', false);
    }

    getSuggestionsForNewSchool(startDate: string, districtId?: string): Observable<AcademicYearResponse[]> {
      let getURL = `api/AcademicYears/SuggestionsForNewSchool?startDate=${startDate}`;
      if (districtId) {
        getURL += `&districtId=${districtId}`;
      }
      return this.apiClient.InvokeApi<AcademicYearResponse[]>(getURL, HttpAction.Get);
    }

    getAcademicYearBySchoolId(schoolId: string, sendSchoolId: boolean = false): Observable<SchoolAcademicYearResponse[]> {
      const getURL = `wpapi/Schools/${schoolId}/AcademicYears`;
      const options: ApiClientOptions = new ApiClientOptions();
      if (sendSchoolId) {
        options.schoolIdHeader = schoolId;
      }
      return this.apiClient.InvokeApi<SchoolAcademicYearResponse[]>(getURL, HttpAction.Get, null, options);
    }

    getAllSchoolAcademicYearsByDistrictId(districtId: string): Promise<SchoolAcademicYearResponse[]> {
      const getURL = `api/Districts/${districtId}/AcademicYears`;
      return this.apiClient.InvokeApi<SchoolAcademicYearResponse[]>(getURL, HttpAction.Get).toPromise();
    }

    getSchoolsAcademicYears(schoolIds: string[]) {
      const url = 'api/Schools/AcademicYears/GetCurrent';

      return this.apiClient.InvokeApi<SchoolAcademicYearResponse[]>(url, HttpAction.PostWithBodyResponse, schoolIds);
    }
}
