import {DictionaryApi} from '../enums/dictionary-api.enum';
import {SettingsPanel} from '../enums/settings-panels-enum';

export class GlobalConstants {
    static GuidPatternRegExp = /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i;
    static appVersion = '20210901.1';
    static AuthKey = 'auth_key';
    static DeviceKey = 'deviceKey';
    static EventHubAuthToken = 'eventHubAuthToken';
    static EventHubUrl = 'eventHubUrl';
    static UserId = 'userId';
    static ReferralAffiliateId = 'referralAffiliateId';
    static CustomerId = 'customerId';
    static UserGroupId = 'userGroupId';
    static ShardKey = 'ShardKey';
    static UserTypeId = 'userTypeId';
    static UserName = 'username';
    static PastLoggedInUsers = 'pastLoggedInUsers';
    static ObjectId = 'objectId';
    static StudentId = 'studentId';
    static Student = 'student';
    static Teacher = 'teacher';
    static ClassId = 'classId';
    static UserSessionId = 'userSessionId';
    static UserSession = 'UserSession';
    static FirstName = 'firstName';
    static LastName = 'lastName';
    static Newsstand = 'Newsstand';
    static CurrentGrade = 'currentGrade';
    static defaultLanguage = 'en';
    static language = 'language';
    static TournamentFavoriteContestants = 'tournamentFavoriteContestants';
    static TournamentPublicInvitesBlackList = 'tournamentPublicBlackList';
    static AssignmentsBlacklist = 'assignmentsBlacklist';
    static Library = 'library';
    static ContentBuilderOnlyAccount = 'ContentBuilderOnlyAccount';
    static TeacherSeenCompetition = 'TeacherSeenCompetition';
    static StudentSeenCompetition = 'StudentSeenCompetition';
    static SortKeys = {
        the: 'the ',
        a: 'a '
    };
    static GoogleClientId = '39302110932-q391adt1q1p45vqdpfu5se5em4murm0b.apps.googleusercontent.com';
    static GoogleCookiePolicy = 'single_host_origin';
    static MicrosoftSSOConfig =
        {
            clientID: 'b32c61df-1250-41c4-a86e-8315b8381933',
            authority: 'https://login.microsoftonline.com/common',
            validateAuthority: true,
            cacheLocation: 'localStorage',
            navigateToLoginRequestUrl: true,
            consentScopes: ['user.read']
        };
    static PowerTextSkipCount = 0;
    static PowerTextTakeCount = 1000;
    static MaxOfflineLoginAttempts = 10;
    static SystemConfigurationStrings = {
        English: 'StudentCheckoutLimitForEnglishTexts',
        Spanish: 'StudentCheckoutLimitForSpanishTexts',
        Teacher: 'TeacherCheckoutLimit',
        TaggingStandards: 'TaggingStandard',
        RequiresEarlyCheckinFeedback: 'RequiresEarlyCheckinFeedback',
        InactivityTimeoutInSeconds: 'InactivityTimeoutInSeconds',
        Language: 'Language',
        VideoLibraryAccess: 'VideoLibraryAccess',
        ActionClipLibraryAccess: 'ActionClipLibraryAccess',
        VideoInBookAccess: 'VideoInBookAccess',
        ActionClipInBookAccess: 'ActionClipInBookAccess',
        PracticeWordsInReader: 'PracticeWordsInReader',
        PracticeWordRulesInReader: 'PracticeWordRulesInReader',
        AllowStudentToSendParentPortalInvite: 'AllowStudentToSendParentPortalInvite'
    };
    static ShelfName = 'shelfName';
    static SubShelfName = 'subShelfName';
    static Shelf = 'shelf';
    static SubShelf = 'subshelf';
    static BookId = '/bookId/';
    static Letter = 'letter';
    static NumericShelfTitle = '0 ~ 9';
    static NumericJumpToOption = '0~9';
    static SpecialCharacterShelfTitle = '@';
    static AnimationForward = 'forward';
    static PowerChallengeCompleted = 'PowerChallengeCompleted';
    static AnimationBackward = 'backward';
    static azureEnvironmentsUrl = 'https://environments.ls-api.com/';
    static DiffDays = {
        DueToday: 'NOWREADING.DUETODAY',
        DueTomorrow: 'NOWREADING.DUETOMORROW',
        DueDays: 'NOWREADING.DIFFDAYS',
        Today: 0,
        Tomorrow: 1,
        MaxDiffDays: 5
    };
    static StorageQuotaMb = 1024; // 200 Mb
    static MinStorageQuotaMb = 40; // 40 Mb
    static BigFileSize = 10; // 10MB
    static SmallFileSize = 5; // 5MB

    static ApplicationBuildType = 'WEB';
    static ApplicationPlatform = 'HTML5';
    static TimeDifferenceBetweenOpeningAndClosingOfPopup = 350; // 350ms - this is the maximum time difference...
    // ...between opening and closing of a popup when it is suppressed by chrome
    static BelowOrZeroLexileRating = 'BR';
    static IncorrectCredentials = 'Incorrect username or password. Please try again.';
    static AccountLocked = 'You had too many incorrect password attempts. Please ask your teacher to reset your password.';
    static NoSSOCredentials = 'No SSO credentials found.';
    static NoCredentials = 'Error retrieving login credentials';
    static NoLicense = 'You do not have a license to use LightSail or your license has expired.';
    static AccessDenied = 'access_denied';
    static EnglishLanguage = 'English';
    static SpanishLanguage = 'Spanish';
    static ChineseLanguage = 'Chinese';
    static FullPercentDownload = 100;
    static DefaultOnlineTimeout = 30; //In Seconds
    static DefaultPingTimeout = 15; //In Seconds
    // Note: 50 seconds result in 60 seconds as 50 seconds have 10 ticks as pingTimeout=5 seconds and
    // each ping take approx 1 sec to get complete
    static DefaultSessionPingTimeout = 150; //In Seconds
    static DefaultSkip = 0;
    static DefaultTake = 20;
    static DefaultAlertTake = 10;
    static DefaultBadgeTake = 25;
    static DefaultBookTake = 25;
    static DefaultElasticResultTake = 10;
    static DefaultHistoricalArticleResultTake = 10;
    static DefaultShelfTake = 4;
    static DefaultInactivityTimeoutInSeconds = 300;
    static DefaultInactivityTimeoutForReaderInSeconds = 300;
    static InactivityTimeoutWarningInSeconds = 10;
    static InactivityTimeoutWarningForReaderInSeconds = 30;
    static MaxApiLogEntries = 5000;
    static MaxBookCache = 20;
    static cryptoAlgName = 'RSA-OAEP';
    static cryptoAlgorithm = 'RSA-OAEP-256';
    static cryptoModLength = 2048;
    static cryptoHashWeb = 'SHA-256';
    static cryptoHashBT = 'SHA-1';
    static cryptoHashKeyUses = ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey'];
    static encInSize = 189;
    static encOutSize = 256;
    static AnimationIn = 'in';
    static BookReadingStatus = {
        NowReading: 'NOWREADING.NOWREADING',
        ReadingFast: 'NOWREADING.READTOOFAST',
        Completed: 'BOOK.COMPLETED',
        Abandoned: 'NOWREADING.ABANDONED'
    };
    static pageSessionSettings = {
        threshold: 2000, //2 seconds, used to detect if sessions are duplicate
        thresholdForPR: 1000,
        readToFastThreshold: 5, //number of pages before warning
        readWellThreshold: 2, //number of pages before warning
        requiredDuration: 3000, //3000ms(3seconds) before we count
        skipInitialPages: 5 //number of pages to skip at the start of a book.
    };
    static PCImages = ['assets/images/PC/L5342.png', 'assets/images/PC/L5705.png', 'assets/images/PC/L5731.png',
        'assets/images/PC/L5809.png', 'assets/images/PC/L5901.png'];
    static ConsumerPCImages = ['assets/images/PC/L5342.png', 'assets/images/PC/L5705.png', 'assets/images/PC/L5731.png',
        'assets/images/PC/L5809.png', 'assets/images/PC/L5901.png'];
    static emDash = '&mdash;';
    static ScreenSessionId = 'screenSessionId';
    static ScreenSessionExpiration = 'screenSessionExpiration';
    static percentSign = '%';
    static DefaultThresholdColor = '#666666';
    static BootstrapSMBreakpoint = 576; //px value of the bootstrap mobile screen breakpoint,
    static BootstrapMDBreakpoint = 768; //px value of the bootstrap mobile screen breakpoint,
    static BootstrapLGBreakpoint = 992; //px value of the bootstrap mobile screen breakpoint,
    static BootstrapXLBreakpoint = 1200; //px value of the bootstrap large screen breakpoint,
    static LexileSuffix = 'L';
    static teacherDashboardFilter = 'teacherDashboardFilter';
    static Article = 'Article';
    static ZeroLexile = '0L';
    static ScreenHeightLimit = 600;
    static AdminType = 'AdminType';
    static SelfServiceOrderId = 'SelfServiceOrderId';
    static ShowOrderComments = 'ShowOrderComments';
    static ServiceRegisterUrl = '../Service/index.html#/register/iOS';
    static ServiceUrl = '../Service/index.html#/dashboard/';
    static ServiceResetUrl = '../Service/index.html#/reset';
    static ServiceGoToUrl = '../Service/index.html#';
    static ServiceLoginUrl = '../Service/index.html#';
    static SignupAppUrl = '/home-schooling/index.html';
    static SessionRedirectingSS = 'redirectingSS';
    static SchoolAccountStatus = 'schoolAccountStatus';
    static Role = 'role';
    static SchoolId = 'schoolId';
    static Observer = 'observer';
    static ReaderInfo = 'readerInfo';
    static SchoolsOnExhibition = ['d6352343-4ede-4db9-3db8-08d50afb19c7', '61a9572f-1c7b-4cd8-b8d5-08d53fdcb1c6',
        '8396C677-AA6E-4EC5-B2F1-08D56E21D597'];
    static cLSClient = '665a46d36204a8d83c43';
    static ClasslinkClient = 'c1536154019710ee499dfbda6d1a84c98ec4fe27e4f0f2';
    static gg4lClientId = 'PTRAPOWSUM';
    static ResetInitiatedURL = 'ResetInitiatedURL';
    static DistrictId = 'districtId';
    static PostMessage = 'userUnauthenticated';
    static DashboardRequest = 'ETGoHome';
    static IsFamily = 'familyInd';
    static DemoStudentInd = 'demoStudentInd';
    static DTUserName = 'dTUserName';
    static DTClassId = 'dTClassId';
    static DemoStudentSurnameSuffix = 'Demo Student';
    static TeacherPwd = 'np';
    static TeacherLogin = 'tl';
    static NoDB = 'ndb';
    static ArticleWordsPerPage = 200;
    static PublisherLoanPeriod = 42; // days
    static MinShowTimePopup = 2000; // 2000ms (2seconds)
    static PageTitle = 'LightSail Education';
    static DefaultDictionary = DictionaryApi.Oxford;
    static MobileWidth = 765;
    static DownloadActive = 'downloadActive';
    static ApproachingGrade = 'approaching';
    static OnGrade = 'on';
    static BelowGrade = 'below';
    static AboveGrade = 'above';
    static ChatMaxWidth = 425;
    static StreamInfoWidth = 800;
    static DoSpeedTests: boolean;
    static SpeedTestTimeout = 30000; // 30 seconds
    static SpeedTestMinInterval = 120; // 2 minutes = 120 seconds
    static UserCacheFirstTimeout = 360; // 6 hours = 6 * 60 minutes.
    static JiraReporterName = 'dan';
    static BadgesGoBack = 'badgesGoBack';
    static ArticleImportFailed = 'ARTICLE_IMPORT_FAILED';
    static ReaderPrevPage = 'readerPrevPage';
    static debugOK = 'Rnlr4BJ2MOgzlgmqfJNiAeswYRl78J6UeRoTHsjLcEs=';
    static DefaultFontSize = 0.85;
    static GuidEmpty = '00000000-0000-0000-0000-000000000000';
    static ClozesEnabled = 'clozesEnabled';
    static UnitEssentialQuestionsMaxCount = 5;
    static AssignmentEssentialQuestionsMaxCount = 3;
    static LastLoginDateToLweb = 'lastLoginDateToLweb';
    static LastLoginDateToLss = 'lastLoginDateToLss';
    static FirstLogin = 'firstLogin';
    static PrivatePolicyLink = 'https://lightsailed.com/about/legal/privacy-policy-for-lightsail-app/';
    static StreamingBrowserError = 'browser Error';
    static StreamingPageError = 'decrypt Page Parameters';
    static PartnerId = 'PartnerId';
    static IsManagedByMindplay = 'IsManagedByMindplay';
    static VoiceThoughtTimeLimit = 2;
    static AwsRegion = 'us-east-1';
    static AwsIdentityPool = 'us-east-1:4276fdbb-6152-421a-b2bb-dcf9900f679a';
    static DefaultWeeklyStudentGoal = 150;
    static DefaultPerDayStudentGoal = 30;
    static DateFormat = 'YYYY-MM-DD[T]HH:mm:ss.[0000000Z]';
    static Base64Regex = /^data:image\/[a-z]+;base64,/igm;
    static DefaultAvatar = 'Reader/assets/images/Default_Avatar.svg';
    static ThumbnailSmall = {
        width: 106,
        height: 160
    };
    static ThumbnailProfile = {
        width: 230,
        height: 347
    };
    static Thumbnail = {
      width: 216,
      height: 320
    };
    static SummaryCountOfWords = 150;
    static AzureImageUrl = 'https://lightsailpublic.blob.core.windows.net/images/';
    static QuillEditorToolbarHeight = 45;
    static BookIdsForAutomationTool = 'bookIdsForAutomationTool';
    static BlockedWordsForDefinition = ['shit', 'shits', 'fuck', 'fucking', 'fucker', 'fuckers', 'damn', 'bitch', 'sex', 'sexual', 'porn', 'ass', 'asshole', 'dyke', 'fag', 'jerkoff'];
    static AuthorBookTutorialUrlStudent = 'https://vimeo.com/699661150/a709730d2f';
    static AuthorBookTutorialUrlTeacher = 'https://vimeo.com/482336845/aa525728fa';
    static RosenPartnerId = 'd2b3e03e-761c-11e9-9ee6-000af7eac480';
    static RosenBaseUrl = 'https://rosen.lightsailed.com';
    static ProductionBaseUrl = 'https://reader.lightsailed.com';

    static CreateTournamentTutorialUrl = 'https://vimeo.com/673287783/f54949839e';
    static ChatTutorialUrl = 'https://vimeo.com/434334503/385fc8db00';
    static NewTeacherDashboardTutorialUrl = 'https://vimeo.com/424907543/5908cf44e1';
    static TeacherDashboardV10TutorialUrl = 'https://vimeo.com/657883223/5950a5d1ba';
    static ContentBuilderTutorialUrl = 'https://vimeo.com/454535983/65ee397266';
    static BookOverlayTutorialUrl = 'https://vimeo.com/449058721/209a59dac0';
    static ClassProgressTutorialUrl = 'https://vimeo.com/454589985/e4b17df583';
    static ClassSettingsTutorialUrl = 'https://vimeo.com/453455543/a46e1a52e9';
    static MasterySkillsTutorialUrl = 'https://vimeo.com/448609179/e937e4cc43';
    static PRTutorialVideo = 'https://vimeo.com/670270265/540eceb06d';
    static PRTutorialVideoConsumer = 'https://vimeo.com/694866627/77c009ae39';
    static TapToListenTutorialUrl = 'https://vimeo.com/674342670/3f61fb8be9';
    static FollowAlongTutorialUrl = 'https://vimeo.com/674342503/0ee1c17ddc';
    static ReadAloudTutorialUrl = 'https://vimeo.com/674342581/ae03cf992b';
    static RepeatedWordsTutorialUrl = 'https://vimeo.com/674342632/a65b7b0772';
    static TimeOnTaskTutorialUrl = 'https://vimeo.com/674342708/5162c2e830';
    static ImagesIconTutorialUrl = 'https://vimeo.com/674342856/03324e33ca';
    static CoverAndRevealSettingsTutorialUrl = 'https://vimeo.com/674342905/cc9d2676be';
    static ShadingSettingsTutorialUrl = 'https://vimeo.com/674342982/c5b6b2f7c6';
    static FontSettingsTutorialUrl = 'https://vimeo.com/674342945/691360cdd3';
    static TextToSpeechAndHighlightSettingsTutorialUrl = 'https://vimeo.com/674343013/45f8b33ca6';
    static SyllabificationAndPartsOfSpeechSettingsTutorialUrl = 'https://vimeo.com/674343224/fb8be0e39f';
    static ScreenContrastAndBorderFocusSettingsTutorialUrl = 'https://vimeo.com/674343135/adf61908d7';
    static BlurModeSettingsTutorialUrl = 'https://vimeo.com/674343080/34abeec55f';
    static SpotlightModeSettingsTutorialUrl = 'https://vimeo.com/674343167/ee1d691f96';
    static LaserModeSettingsTutorialUrl = 'https://vimeo.com/674343113/e63b6e9703';
    static NewsTickerModeSettingsTutorialUrl = 'https://vimeo.com/674343920/69d2f83889';
    static WordBubbleModeSettingsTutorialUrl = 'https://vimeo.com/674344036/4a3dedd7ad';
    static TemplatesSettingsTutorialUrl = 'https://vimeo.com/674343987/f2f8ff23b2';
    static LightSailTutorialVideoUrl = 'https://vimeo.com/680283592/e428bab1bf';
    static LightSailTutorialVideoUrlV10 = 'https://vimeo.com/798349909/7812f02258';
    static ReadToReadVideo = 'https://vimeo.com/697887105/7cd61ffdfb';
    static ClassCodeTutorialVideo = 'https://vimeo.com/681738694/11df95a910';
    static GrammarRemediation = 'https://vimeo.com/801666061/1f0b05faca';

    static Dashboard = 'https://vimeo.com/657883223/5950a5d1ba';
    static StudentDashboard = 'https://vimeo.com/703872389/14191aeb2c';
    static ContentBuilder = 'https://vimeo.com/659385391/411c226505';
    static FluencyBuilder = 'https://vimeo.com/661971142/0840c036b8';
    static RunningRecord = 'https://vimeo.com/662948459/5c6a30ddee';
    static Homework = 'https://vimeo.com/662541230/d754a10feb';
    static BookClub = '';
    static BookLibrary = 'https://vimeo.com/699483816/9d184afb0e';
    static VideoLibrary = 'https://vimeo.com/699576055/c4a3adb098';
    static SpellingBee = 'https://vimeo.com/699683365/c9b9354fe4';
    static ClassProgress = 'https://vimeo.com/660694759/3dddd8c75e';
    static CurrentStudentActivity = '';
    static Reports = '';
    static Trends = '';
    static SchoolSettings = '';
    static Rosters = '';
    static ClassSettings = 'https://vimeo.com/679079520/10b295b5d5';
    static RubricBuilder = '';
    static StudentAuthoredBooks = 'https://vimeo.com/699661150/a709730d2f';
    static BuyBooks = 'https://vimeo.com/679078214/463e920492';
    static Badges = '';
    static Portfolio = '';
    static WordWork = 'https://vimeo.com/699483034/99c536b092';
    static StudentTournaments = 'https://vimeo.com/699482896/02f4755eb7';
    static Tournaments = 'https://vimeo.com/673287616/687f4463b0';
    static PersonalizedReader = 'https://vimeo.com/691581793/54fe4b907a';
    static BookStore = '';
    static SpellingBeeTeacherTutorial = 'https://vimeo.com/701074559/0827ad5d43';
    static SynchronizedReading = 'https://vimeo.com/707537164/39392d87a4';
    static StudentSynchronizedReading = '';
    static VocabularyTeaching = 'https://vimeo.com/708714814/abda41e1e9';
    static WritingSolutions = 'https://vimeo.com/710371440/3db44936ad';


    static Password = 'password';
    static ExcludedSettingsForChinaRegion = [
      SettingsPanel.License,
      SettingsPanel.CameraAccess,
      SettingsPanel.HiddenBooks,
      SettingsPanel.HiddenVideos,
    ];
    static ClassicsAppropriateLevelId = 179;
    static IsConsumerUser = 'isConsumerUser';
    static USCountryISO = 'US';
    static USCountryId = 'bab639ff-78df-e711-a94c-000d3a34c28a';
    static IsraelCountryId = '3bb639ff-78df-e711-a94c-000d3a34c28a';
    static ICEIStandardId = '1e6e452a-5db8-11ec-a2f0-000af7eac480';
    static IsraelPartnerId = '95d9c331-8f7e-4dd6-d959-08d99eb03c4a';
    static ThemeAttribute = 'data-theme';
    static OverrideStandard = 'OverrideStandard';
    static OverrideOtherStandardId = 'OverrideOtherStandardId';
    static BESTStandardId = '91201ef6-382b-11ed-b4a0-000af7eac480';
    static GAStandardId = 'a02c7a3e-382b-11ed-b4a0-000af7eac480';
    static TEKSStandardId = 'e624ed69-9f49-4b28-ca8a-08d6a54a1018'

    static DefaultTimeOnTaskSetup = '20:10';
    static ICEIEnglishDemoSchoolDistrictId = '94f96be1-d4cf-41d4-9436-08d976915085';
    static ICEILiveSchoolUserGroupOrganizationName = 'המרכז הישראלי לחדשנות בחינוך';

    static TournamentsPerPagePairs = 32;
    static TournamentsLeaderboardPerPageContestants = 100;
    static EmptyDate = '0001-01-01T00:00:00.0000000Z';
    static SelectedStudentForWordList = 'selectedStudentForWordList';
    static SelectedStudentForAuthenticWriting = 'selectedStudentForAuthenticWriting';
    static SelectedStudentForGrammarAssignments = 'selectedStudentForGrammarAssignments';
    static CurrentStudentActivityColumnState = 'currentStudentActivityColumnState';
    static DecodableStudentActivityColumnState = 'decodableStudentActivityColumnState';
    static HideSubGroupName = 'hideSubGroupName';
    static AbLightsailEducationSchoolId = 'ec4bfd6c-b518-4db6-0bd5-08d7f633ce42';
    static PS165SchoolId = '8396c677-aa6e-4ec5-b2f1-08d56e21d597';
    static HasSeenTournamentVideo = 'HasSeenTournamentVideo';
    static HasSeenV10Dialog = 'HasSeenV10Dialog';
    static V10IntroductionVideo = 'https://vimeo.com/798351493/1f4e9c5fc3';
    static MaintenanceStartTime = '2023-11-23T14:00:00.0000000Z';
    static MaintenanceEndTime = '2023-11-26T22:00:00.0000000Z';
    static MSQIDistrictId = 'd0e3f6c1-dd53-48a2-b55a-08d8e8839bc6';
    static V9ReaderUrl = '/schoolv9/literacy/#/'
    static V9SalesUrl = 'https://sales.lightsailed.com/Reader/#/'
    static March2023ReadingTournamentId = 'f25d2aca-4001-4790-8454-08db14e22ab1';
    static MarchReadingTournamentVideo = 'https://vimeo.com/678163232/00e248a93e';
    static RocketShipDistrictId = '05e8b4cc-4992-49de-bb86-08d5ed56410f';
    static SalesVideoUserId = '417dd9f5-75f1-48e5-ac07-08da7523d6f1';
    static DecodableHiGradeSets = ['MS-K', 'MS-1', 'HS-2', 'HS-3'];
    static MindPlayPartnerId = '17c0fc2b-5d10-4a21-3e66-08db1e514329';
    static EllBookTagId = '0ea8ba6d-76ea-11ee-af12-000af7eac480';
    static EllBookTagValue = 'ELL Book';
    static VirtualLibraryId = '63d013c8-c9b3-11ee-817b-4c796e202f2d';
    static IDSToken = 'IDSToken';
    static MPToken = 'MPToken';
    static AuthenticationMethod = 'authenticationMethod';
    static CustomAudioBookPrefix = 'CUSTOM_AUDIO_BOOK-';
    static MexicanTournamentIdEn = '7746e6b0-bc3e-4807-7d54-08dcf9443cf0';
    static MexicanTournamentIdEs = '612f69eb-f2da-4d0b-0878-08dcfa37eaca';
    static MexicanTournamentIds = [GlobalConstants.MexicanTournamentIdEn, GlobalConstants.MexicanTournamentIdEs, '83786380-e3e3-4797-7d52-08dcf9443cf0'];
}
