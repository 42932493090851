import {Store} from '@ngrx/store';
import {AppStateService} from '../../../../../../src/app/app.service';
import {SessionStorageService} from '../../../../../../src/app/localstorage/session-storage.service';
import {AppStateConstants, GlobalConstants} from '../../../../../../src/app/globals';
import {environment} from '../../../environments/environment';
import {AuthActions} from '../store/auth/auth.actions';
import {StringUtils} from '../../../../../../src/app/shared/utils/string.utils';
import {DeviceTypeUtils} from '../../shared/utils/device-type-utils';

export function initializeApp(store: Store, appStateService: AppStateService) {
  return () => {
    // This is being used in V10 legacy code
    appStateService.set(AppStateConstants.lightSailServerURL, environment.lsApiUrl);

    if (SessionStorageService.Get(GlobalConstants.IDSToken)) {
      // store.dispatch(AuthActions.authenticateSuccess({accessToken: SessionStorageService.Get(GlobalConstants.AuthKey)}))
      //@TODO: Rehydrate the LS and MP auth responses from localstorage or IDB to avoid calling authenticate again
      store.dispatch(AuthActions.authenticateLSMP({token: SessionStorageService.Get(GlobalConstants.IDSToken)}));
    } else {
      const identityServerToken = new URL(window.location.href).searchParams.get('it');
      if (identityServerToken) {
        try {
          const parsedToken = StringUtils.parseJwt(identityServerToken);
          // When user is redirected from clever or classlink then user first goes to account.mindplay.com and then redirected to studio
          // and the identity token in the url will contain authenticationMethod which will be used to auto close the tab on logout
          SessionStorageService.Save(GlobalConstants.AuthenticationMethod, parsedToken[GlobalConstants.AuthenticationMethod]);

          // If the app is opened in in-app webview like classlink ios app then store the credentials and auth method to
          if (parsedToken[GlobalConstants.AuthenticationMethod] === 'classLink' && DeviceTypeUtils.isiPadInAppWebView()) {
            window.localStorage.setItem(GlobalConstants.AuthenticationMethod, parsedToken[GlobalConstants.AuthenticationMethod]);
            window.localStorage.setItem('c', window.btoa(`${parsedToken['customerId']}|${parsedToken['username']}|${parsedToken['password']}`));
          }
          if (parsedToken['customerId'] && parsedToken['username'] && parsedToken['password']) {
            store.dispatch(AuthActions.authenticate({
              customerId: parsedToken['customerId'],
              username: parsedToken['username'],
              password: parsedToken['password']
            }));
          } else {
            store.dispatch(AuthActions.authenticateLSMP({token: identityServerToken}));
          }
        } catch (e) {

        }
      }
    }

    return true;
  };
}
