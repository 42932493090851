import {ErrorHandler, inject, Injectable} from '@angular/core';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {AngularPlugin} from '@microsoft/applicationinsights-angularplugin-js';
import {ENV_CONFIG} from '../../environment.config';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService {
  private environmentConfig = inject(ENV_CONFIG);
  private router = inject(Router);

  private angularPlugin = new AngularPlugin();
  private appInsights = new ApplicationInsights({
    config: {
      connectionString: this.environmentConfig.applicationInsightsConnectionString,
      // *** If you're adding the Click Analytics plug-in, delete the next line. ***
      extensions: [this.angularPlugin],
      // *** Add the Click Analytics plug-in. ***
      // extensions: [angularPlugin, clickPluginInstance],
      extensionConfig: {
        [this.angularPlugin.identifier]: {
          router: this.router,
          errorServices: [new ErrorHandler()],
        }
        // *** Add the Click Analytics plug-in. ***
        // [clickPluginInstance.identifier]: clickPluginConfig
      }
    }
  });

  constructor() {
    if (this.environmentConfig.applicationInsightsConnectionString) {
      this.appInsights.loadAppInsights();
    }
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  // expose methods that can be used in components and services
  trackEvent(name: string): void {
    this.appInsights.trackEvent({ name });
  }

  trackTrace(message: string): void {
    this.appInsights.trackTrace({ message });
  }
}
