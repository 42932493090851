import {Injectable} from '@angular/core';
import moment from 'moment';
import {GlobalConstants} from '../globals';
import {isNullOrUndefined} from 'app/shared/utils/global.utils';
import {SortOrder} from '../search/search-models';

@Injectable({providedIn: 'root'})
export class SortService {
    sort<T>(value: Array<T>, filter: string | ((obj: T) => string | number), ascending: boolean = true, excludeArticle?: boolean, verifyTitle?: boolean, verifyFilter?: string): Array<T> {
        return value.sort((a, b) => {
            let itemA = this.getSortValue(a, filter);
            let itemB = this.getSortValue(b, filter);

            if (typeof itemA === 'string') {
                itemA = itemA.toLowerCase().trim();
            }
            if (typeof itemB === 'string') {
                itemB = itemB.toLowerCase().trim();
            }

            if (verifyTitle && verifyFilter == null) {
                if (itemA == itemB) {
                    return this.getPlacement((<any>a).bookId, (<any>b).bookId, ascending);
                }
            }
            else if (verifyTitle && verifyFilter != null) {
                if (itemA == itemB) {
                    return this.getPlacement(a[verifyFilter], b[verifyFilter], ascending);
                }
            }

            if (excludeArticle) {
                if (!isNullOrUndefined(itemA) && !isNullOrUndefined(itemB) && !isNullOrUndefined(itemA.length) && !isNullOrUndefined(itemB.length)) {
                    return this.getPlacementWithoutArticles(itemA, itemB, ascending);
                }
                else {//items are not characters
                    return this.getPlacement(itemA, itemB, ascending);
                }
            }
            else {
                return this.getPlacement(itemA, itemB, ascending);
            }
        });
    }

    sortDateString<T>(value: Array<T>, filter: string | ((a: T) => string), ascending: boolean = true): T[] {
        return value.sort((a, b) => {
            const itemA: Date = moment(this.getSortValue(a, filter)).toDate();
            const itemB: Date = moment(this.getSortValue(b, filter)).toDate();

            return this.getPlacement(itemA, itemB, ascending);
        });
    }

    private getSortValue<T>(object: T, filter: string | number | ((a: T) => string | number)) {
        return typeof filter === 'function' ? filter(object) : object[filter];
    }

    getPlacement(a, b, ascending: boolean): number {

        if (ascending) {
            if (a > b)
                return 1;
            else if (a < b)
                return -1;
            else if (isNullOrUndefined(a) && !isNullOrUndefined(b))
                return -1;
            else if (!isNullOrUndefined(a) && isNullOrUndefined(b))
                return 1;
            else
                return 0;
        }
        else {
            if (a < b)
                return 1;
            else if (a > b)
                return -1;
            else if (isNullOrUndefined(a) && !isNullOrUndefined(b))
                return 1;
            else if (!isNullOrUndefined(a) && isNullOrUndefined(b))
                return -1;
            else
                return 0;
        }
    }

    getPlacementWithoutArticles(a, b, ascending): number {
        let itemA = a;
        let itemB = b;

        //check for special characters around the title
        let reg = new RegExp('^([^ A-Za-z0-9]+)');
        let execA = reg.exec(itemA);
        let execB = reg.exec(itemB);

        //test and get index of characters
        if (!isNullOrUndefined(execA)) {
            itemA = itemA.substring(execA[0].length);
        }
        if (!isNullOrUndefined(execB)) {
            itemB = itemB.substring(execB[0].length);
        }

        //remove artices
        if (itemA.indexOf(GlobalConstants.SortKeys.a) == 0 && itemA.length > 2) {
            itemA = itemA.substring(2);
        }
        else if (itemA.indexOf(GlobalConstants.SortKeys.the) == 0 && itemA.length > 4) {
            itemA = itemA.substring(4)
        }
        if (itemB.indexOf(GlobalConstants.SortKeys.a) == 0 && itemB.length > 2) {
            itemB = itemB.substring(2);
        }
        else if (itemB.indexOf(GlobalConstants.SortKeys.the) == 0 && itemB.length > 4) {
            itemB = itemB.substring(4);
        }

        if (itemA == itemB) return this.getPlacement(a.bookId, b.bookId, ascending);
        return this.getPlacement(itemA, itemB, ascending);
    }

    sortNumber(value: Array<any>, filter: string) {
        return value.sort((a, b) => {
            return Number(a[filter]) - Number(b[filter]);
        });
    }

    sortBySelected(array: any[], labelProp: string, selectedProp: string): any[] {
        if (array && array.length > 0) {
            if (labelProp !== null) {
                array = this.sort(array, labelProp);
            }
            array = this.sort(array, selectedProp, false);
        }
        return array;
    }

    sortByCompoundFilterOnly(value: Array<any>, filter: string, secondaryFilter: string, ascending: SortOrder = SortOrder.Ascending): any[] {
        return value.sort((a, b) => {
            let itemA = a[filter][secondaryFilter];
            let itemB = b[filter][secondaryFilter];

            if (typeof itemA == "string") {
                itemA = itemA.toLowerCase();
            }
            if (typeof itemB == "string") {
                itemB = itemB.toLowerCase();
            }

            return this.getPlacement(itemA, itemB, ascending === SortOrder.Ascending);
        });
    }
}
