import {Routes} from '@angular/router';
import {authGuard} from './core/guards/auth.guard';
import {clientResolver} from './modules/client/client.resolver';
import {SsoProvider} from '../../../../src/app/enums/sso-provider.enum';

export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./modules/login/login.component').then(m => m.LoginComponent),
    title: 'Login'
  },
  {
    path: 'signup',
    loadComponent: () => import('./modules/signup/signup.component').then(m => m.SignupComponent),
    title: 'Signup'
  },
  {
    path: 'clever',
    loadComponent: () => import('./modules/login/login.component').then(m => m.LoginComponent),
    title: 'Login',
    data: { ssoProvider: SsoProvider.Clever }
  },
  {
    path: 'nycidp',
    loadComponent: () => import('./modules/login/login.component').then(m => m.LoginComponent),
    title: 'Login',
    data: { ssoProvider: SsoProvider.NYCIDP }
  },
  {
    path: '',
    loadChildren: () => import('./modules/client/client.routes').then(m => m.ClientRoutes),
    canActivate: [authGuard],
    resolve: {
      data: clientResolver
    },
    title: 'MindPlay Studio'
  },
  {
    path: 'account-setup',
    loadComponent: () => import('./modules/account-setup/account-setup.component').then(m => m.AccountSetupComponent),
    title: 'Account Setup'
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./modules/reset-password/reset-password.component').then(m => m.ResetPasswordComponent),
    title: 'Reset Password'
  },
  {
    path: 'email',
    loadComponent: () => import('./modules/welcome-email/welcome-email.component').then(m => m.WelcomeEmailComponent),
    title: 'Reset Password'
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  },
];
