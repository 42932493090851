import {APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, isDevMode} from '@angular/core';
import {provideRouter} from '@angular/router';
import {routes} from './app.routes';
import {HttpRequest, provideHttpClient, withInterceptors} from '@angular/common/http';
import {ENV_CONFIG} from './environment.config';
import {environment} from '../environments/environment';
import {provideServiceWorker} from '@angular/service-worker';
import {AppStateService} from '../../../../src/app/app.service';
import {Store} from '@ngrx/store';
import {errorInterceptor} from './core/interceptors/error-interceptor';
import {initializeApp} from './core/services/app-initializer.service';
import {msalInit} from './core/services/msal-initializer.service';
import {storeImports} from './core/store';
import {legacyServices} from './core/services/legacy-services';
import {NgOpenCVModule, OpenCVOptions} from '@lightsailed/ngx-open-cv';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideNgHttpCaching, withNgHttpCachingInterceptor} from './core/ng-http-caching/ng-http-caching-providers';
import {NG_HTTP_CACHING_MONTH_IN_MS, NgHttpCachingConfig, NgHttpCachingStrategy} from './core/ng-http-caching/ng-http-caching.service';
import {NgHttpCachingLocalStorage} from './core/ng-http-caching/storage/ng-http-caching-local-storage';
import {ConnectionServiceModule} from 'ng-connection-service';
import {offlineInterceptor} from './core/interceptors/offline-interceptor';
import {ApplicationinsightsAngularpluginErrorService} from '@microsoft/applicationinsights-angularplugin-js';
import {ApplicationInsightsService} from './core/services/application-insights.service';

const openCVConfig: OpenCVOptions = {
  scriptUrl: 'assets/opencv/asm/3.4/opencv.js',
  usingWasm: false,
};

// cache config...
const ngHttpCachingConfig: NgHttpCachingConfig = {
  lifetime: NG_HTTP_CACHING_MONTH_IN_MS, // cache expire after 30 days
  cacheStrategy: NgHttpCachingStrategy.DISALLOW_ALL,
  allowedMethod: ['GET', 'POST', 'HEAD'],
  version: '1',
  store: new NgHttpCachingLocalStorage(),
  getKey: (req: HttpRequest<any>): string | undefined => {
    // Custom unique key to store the data
    return req.method + '@' + req.urlWithParams;
  },
  isCacheFirst: () => {
    return false;
  }
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([
      errorInterceptor,
      withNgHttpCachingInterceptor(),
      offlineInterceptor
    ])),
    provideNgHttpCaching(ngHttpCachingConfig),
    {
      provide: ENV_CONFIG,
      useValue: environment
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [Store, AppStateService]
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    provideAnimationsAsync(),

    // Microsoft Application Insights
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
      deps: [ApplicationInsightsService]
    },
    // Microsoft Application Insights - END

    importProvidersFrom([
      // Microsoft SSO module initialization
      msalInit,
      // Open CV module initialization
      NgOpenCVModule.forRoot(openCVConfig),
      ConnectionServiceModule
    ]),

    ...legacyServices,
    ...storeImports
  ]
};
