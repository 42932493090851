export enum ReaderAction {
    StoreStartBookmark = 0,
    StoreEndBookMark,
    AddAssessment,
    OpenCloze,
    TextSelection,
    ValidateCfis,
    ResizeReader,
    ScrollIntoAnnotation,
    Search,
    ExtractTextForBookmarks,
    ReloadBookmarkAnnotations,
    ReloadQuotationAnnotations,
    AddAnnotationForBookmark,
    GoToImage,
    SendPageSession,
    ExtractTextInSentences,
    VisiblePageTextInSentences,
    HighlightGroupReadingText,
    UnhighlightGroupReadingText,
    PageTurn,
    PageTurned,
    ReaderLayoutUpdated,
    ReloadWordRuleAnnotations,
    ReloadSectionAnnotations
}
