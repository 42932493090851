// For reference, here are the user agent strings of multiple devices
/*
Useragent Mac safari:
Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/18.2 Safari/605.1.15

Useragent iPad safari
Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/18.2 Safari/605.1.15

Useragent ipad firefox
Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/18.2 Safari/605.1.15

Useragent ipad in-app
Mozilla/5.0 (iPad; CPU OS 18_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Safari/605.1.15

Useragent iPhone safari
Mozilla/5.0 (iPhone; CPU iPhone OS 18_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/18.2 Mobile/15E148 Safari/604.1

Useragent iPhone opera
Mozilla/5.0 (iPhone; CPU iPhone OS 18_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/18.2 Mobile/15E148 Safari/604.1 OPT/5.2.0

Useragent iPhone in-app
Mozilla/5.0 (iPhone; CPU iPhone OS 18_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Safari/605.1.15

Useragent Mac chrome
Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36

Useragent iPhone chrome
Mozilla/5.0 (iPhone; CPU iPhone OS 18_2_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/131.0.6778.103 Mobile/15E148 Safari/604.1

Useragent iPad chrome
Mozilla/5.0 (iPad; CPU OS 18_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/131.0.6778.154 Mobile/15E148 Safari/605.1.15
 */

export class DeviceTypeUtils {
  static isiPadInAppWebView() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safariEngine = /safari/.test(userAgent);
    const safariBrowser = /version\//.test(userAgent);
    const chromeBrowser = /chrome/.test(userAgent) || /crios/.test(userAgent);
    const ios = /iphone|ipad/.test(userAgent);
    const mobile = /mobile/.test(userAgent);
    return safariEngine && ios && mobile && !safariBrowser && !chromeBrowser;
  }
}
