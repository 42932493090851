import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as AuthSelectors from '../auth/auth.selectors';
import * as fromUser from './user.reducer';
import {CallState} from '../../../../../../consumer/src/app/models/enums/call-state';
import {extractErrorMessage} from '../../../shared/utils/extract-error-message';
import {EUniversalScreenerStatus} from '../../models/universal-screener-model';
import {SystemConfigurationTypeEnum} from '../../../../../../../src/app/enums';

export const selectUserState = createFeatureSelector<fromUser.State>(
  fromUser.userFeatureKey,
);

export const selectUserDetailsCallState = createSelector(selectUserState, state => state.userData?.callState);

export const selectStudent = createSelector(selectUserState, state => state.userData?.data?.student);
export const selectTeacher = createSelector(selectUserState, state => state.userData?.data?.teacher);
export const selectStudentGrade = createSelector(
  selectStudent,
  (state) => state?.currentGrade,
);
export const selectStudentFirstName = createSelector(
  selectStudent,
  (state) => state?.firstName,
);
export const selectStudentLastName = createSelector(
  selectStudent,
  (state) => state?.lastName,
);
export const selectLexileRating = createSelector(
  selectStudent,
  (state) => state?.lexileRating,
);
export const selectStudentUsername = createSelector(
  selectStudent,
  (state) => state?.username,
);
export const selectStudentFullName = createSelector(
  selectStudentFirstName,
  selectStudentLastName,
  (firstName, lastName) => `${firstName} ${lastName}`,
);
export const selectStudentAvatarUrl = createSelector(
  selectStudent,
  (state) => state?.avatarImageUrl,
);
export const selectStudentLexileInfo = createSelector(
  selectUserState,
  (state) => state?.studentLexileInfo.data,
);
export const selectStudentLexileBadgeType = createSelector(
  selectStudentLexileInfo,
  (state) => state?.badgeType,
);

export const selectTeacherFirstName = createSelector(
  selectTeacher,
  (state) => state?.firstName,
);
export const selectTeacherLastName = createSelector(
  selectTeacher,
  (state) => state?.lastName,
);
export const selectTeacherUsername = createSelector(
  selectTeacher,
  (state) => state?.username,
);
export const selectTeacherFullName = createSelector(
  selectTeacherFirstName,
  selectTeacherLastName,
  (firstName, lastName) => `${firstName} ${lastName}`,
);
export const selectTeacherAvatarUrl = createSelector(
  selectTeacher,
  (state) => state?.avatarImageUrl,
);
export const selectUserFirstName = createSelector(
  AuthSelectors.selectIsStudent,
  AuthSelectors.selectIsSuperAdmin,
  selectStudentFirstName,
  selectTeacherFirstName,
  (isStudent, isAdmin, studentFirstName, teacherFirstName) => {
    if (isAdmin) {
      return $localize`PLS`;
    }

    return isStudent ? studentFirstName : teacherFirstName;
  }
)
export const selectUserLastName = createSelector(
  AuthSelectors.selectIsStudent,
  AuthSelectors.selectIsSuperAdmin,
  selectStudentLastName,
  selectTeacherLastName,
  (isStudent, isAdmin, studentLastName, teacherLastName) => {
    if (isAdmin) {
      return $localize`Admin`;
    }

    return isStudent ? studentLastName : teacherLastName;
  }
)
export const selectUserAvatarUrl = createSelector(
  AuthSelectors.selectIsStudent,
  selectStudentAvatarUrl,
  selectTeacherAvatarUrl,
  (isStudent, studentAvatarUrl, teacherAvatarUrl) => {
    return isStudent ? studentAvatarUrl : teacherAvatarUrl;
  }
)
export const selectUserFullName = createSelector(
  selectUserFirstName,
  selectUserLastName,
  (firstName, lastName) => {
    return `${firstName} ${lastName}`;
  }
)

export const selectIsInPowerTextMode = createSelector(selectUserState, state => state.isInPowerTextMode?.data);

export const selectSystemConfigurationData = createSelector(selectUserState, state => state.systemConfigurations.data);
// If not found then returns default value true
export const selectSystemConfigurationByType = (type: SystemConfigurationTypeEnum) =>
  createSelector(selectSystemConfigurationData, systemConfiguration => systemConfiguration[type] ?? true);
export const selectIsAudioBooksEnabled = createSelector(selectSystemConfigurationByType(SystemConfigurationTypeEnum.AudioBooksEnabled), enabled => enabled);
export const selectIsSpanishBooksEnabled = createSelector(selectSystemConfigurationByType(SystemConfigurationTypeEnum.SpanishBooksEnabled), enabled => enabled);

export const selectUpdateUserLoading = createSelector(selectUserState, state => state.updateUser.callState === CallState.Loading);
export const selectUpdateUserError = createSelector(selectUserState, state => state.updateUser.callState === CallState.Error ? extractErrorMessage(state.updateUser.error) : null);
export const selectUpdateUserLoaded = createSelector(selectUserState, state => state.updateUser.callState === CallState.Loaded);

export const selectUpdatePasswordLoading = createSelector(selectUserState, state => state.updatePassword.callState === CallState.Loading);
export const selectUpdatePasswordError = createSelector(selectUserState, state => state.updatePassword.callState === CallState.Error ?
  (state.updatePassword.error?.error?.status === 400 ? $localize`The current password entered is incorrect.` : extractErrorMessage(state.updatePassword.error)) : null);
export const selectUpdatePasswordLoaded = createSelector(selectUserState, state => state.updatePassword.callState === CallState.Loaded);

export const selectUniversalScreenerData = createSelector(selectUserState, state => state.universalScreenerStatus.data)
export const selectUniversalScreenerLoaded = createSelector(selectUserState, state => state.universalScreenerStatus.callState === CallState.Loaded)
export const selectUniversalScreenerLoading = createSelector(selectUserState, state => state.universalScreenerStatus.callState === CallState.Loading)
export const selectUniversalScreenerStatus = createSelector(selectUserState, state => state.universalScreenerStatus.data?.status)
export const selectUniversalScreenerShowPopup = createSelector(selectUserState, state => {
  const status = state.universalScreenerStatus.data?.status;
  const loaded = state.universalScreenerStatus.callState === CallState.Loaded
  const noPopupStatuses = [EUniversalScreenerStatus.COMPLETE, EUniversalScreenerStatus.NO_CURRENT_SCHOOL_YEAR]

  return !!loaded && !!status && !noPopupStatuses.includes(status);
})
