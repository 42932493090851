import {createFeature, createReducer, on} from '@ngrx/store';
import {LsMpClassResponse} from '../../models/ls-mp-class-response';
import {
  AuthActions,
  CheckTokenActions,
  ResetPasswordActions,
  ScreenSessionActions,
  SetupAccountActions,
  SetupUserSchoolActions,
  SignupAccountActions,
  UpdateAuthDataActions,
  UserClassesAction,
  UserSchoolsActions,
  UserSessionActions
} from './auth.actions';
import {CallState} from '../../../../../../consumer/src/app/models/enums/call-state';
import {ApiResponse} from '../../models/api-response';
import {AuthData} from '../../models/auth-data';
import {ScreenSessionResponse} from '../../../../../../../src/app/models';
import {SchoolMetaDataResponse} from '../../../../../../../src/app/models/school-meta-data-response';
import {UserClassesData} from '../../models/user-classes-data';
import {UserResponseModel} from '../../models/user-response-model';
import {StudioSchoolResponse} from '../../models/studio-school-response';

export const authFeatureKey = 'auth';

export interface State {
  authData: ApiResponse<AuthData>;
  userSchools: ApiResponse<StudioSchoolResponse[]>;
  selectedSchool: ApiResponse<{school: StudioSchoolResponse, metaData: SchoolMetaDataResponse, isAllSelected: boolean }>;
  userClassesData: ApiResponse<UserClassesData>;
  selectedClass: { selected: LsMpClassResponse, isAllSelected: boolean };
  userSessionId: string;
  screenSession: ScreenSessionResponse;
  authFlowState: CallState;
  tokenValidation: ApiResponse<UserResponseModel>;
  resetPassword: ApiResponse<null>;
  setupAccount: ApiResponse<null>;
  resendSetupAccount: ApiResponse<null>;
  signupAccount: ApiResponse<null>;
}

export const initialState: State = {
  authData: {data: null, error: null, callState: CallState.Initial},
  userSchools: {data: null, error: null, callState: CallState.Initial},
  selectedSchool: {data: null, error: null, callState: CallState.Initial},
  userClassesData: {data: null, error: null, callState: CallState.Initial},
  selectedClass: null,
  userSessionId: null,
  screenSession: null,
  authFlowState: CallState.Initial,
  tokenValidation: {data: null, error: null, callState: CallState.Initial},
  resetPassword: {data: null, error: null, callState: CallState.Initial},
  setupAccount: {data: null, error: null, callState: CallState.Initial},
  resendSetupAccount: {data: null, error: null, callState: CallState.Initial},
  signupAccount: {data: null, error: null, callState: CallState.Initial}
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.authenticate, AuthActions.authenticateSSO, state => ({
    ...state,
    authData: {
      data: null,
      error: null,
      callState: CallState.Loading
    },
    authFlowState: CallState.Loading
  })),
  on(AuthActions.authenticateLSMP, (state, action) => ({
    ...state,
    authData: !action.refresh ? {
      data: null,
      error: null,
      callState: CallState.Loading
    } : state.authData,
    authFlowState: !action.refresh ? CallState.Loading : state.authFlowState
  })),
  on(AuthActions.authenticateSuccess, (state, action) => ({
    ...state,
    authData: {
      data: {idsAccessToken: action.idsAccessToken, idTokenInfo: action.idsTokenInfo, lsAuthResponse: action.lsAuthResponse, mpAuthResponse: action.mpAuthResponse},
      error: null,
      callState: CallState.Loaded
    }
  })),
  on(AuthActions.authenticateFailure, (state, action) => ({
    ...state,
    authData: {
      data: null,
      callState: CallState.Error,
      error: action.error
    },
    authFlowState: CallState.Error
  })),
  on(
    AuthActions.logoutMPManagerUser,
    AuthActions.logoutMPManagerUserSuccess,
    AuthActions.logoutMPManagerUserFailure,
    (state, action) => (state)),


  on(UserSchoolsActions.loadUserSchools, (state) => ({
    ...state,
    userSchools: {
      data: null,
      error: null,
      callState: CallState.Loading
    },
  })),
  on(UserSchoolsActions.loadUserSchoolsSuccess, (state, action) => ({
    ...state,
    userSchools: {
      data: [...action.schools]?.sort((a,b) => a.userGroupOrganizationName < b.userGroupOrganizationName ? -1 : a.userGroupOrganizationName > b.userGroupOrganizationName ? 1 : 0),
      error: null,
      callState: CallState.Loaded
    }
  })),
  on(UserSchoolsActions.loadUserSchoolsFailure, (state, action) => ({
    ...state,
    userSchools: {
      data: null,
      error: action.error,
      callState: CallState.Error
    },
    authFlowState: CallState.Error
  })),

  on(SetupUserSchoolActions.setupUserSchool, (state) => ({
    ...state,
    selectedSchool: {
      data: null,
      error: null,
      callState: CallState.Loading
    },
    selectedClass: null
  })),
  on(SetupUserSchoolActions.setupUserSchoolSuccess, (state, action) => ({
    ...state,
    selectedSchool: {
      data: {school: action.school, metaData: action.metaData, isAllSelected: action.isAllSelected },
      error: null,
      callState: CallState.Loaded
    }
  })),
  on(SetupUserSchoolActions.setupUserSchoolFailure, (state, action) => ({
    ...state,
    selectedSchool: {
      data: null,
      error: action.error,
      callState: CallState.Error
    },
    authFlowState: CallState.Error
  })),
  on(SetupUserSchoolActions.selectAllSchools, (state) =>
    ({
      ...state,
      selectedSchool: {...state.selectedSchool, data: {...state.selectedSchool.data, isAllSelected: true}},
      selectedClass: {...state.selectedClass, isAllSelected: true}
    })
  ),

  on(UserClassesAction.loadUserClasses, state => ({
    ...state,
    userClassesData: {
      data: null,
      error: null,
      callState: CallState.Loading
    }
  })),
  on(UserClassesAction.loadUserClassesSuccess, (state, action) => ({
    ...state,
    userClassesData: {
      data: action.userClassesData,
      callState: CallState.Loaded,
      error: null,
    }
  })),
  on(UserClassesAction.loadUserClassesFailure, (state, action) => ({
    ...state,
    userClassesData: {
      data: null,
      callState: CallState.Error,
      error: action.error
    },
    authFlowState: CallState.Error
  })),
  on(UserClassesAction.selectUserClass, (state, action) => ({
    ...state,
    selectedClass: {selected: action.selectedClass, isAllSelected: action.isAllSelected}
  })),
  on(UserClassesAction.selectAllClasses, (state, action) => ({
    ...state,
    selectedClass: {...state.selectedClass, isAllSelected: true}
  })),
  on(UserSessionActions.startUserSessionSuccess, (state, action) => ({
    ...state,
    userSessionId: action.sessionId
  })),
  on(ScreenSessionActions.logScreenSessionSuccess, (state, action) => ({
    ...state,
    screenSession: action.data
  })),

  on(AuthActions.authFlowCompleted, (state, action) => ({...state, authFlowState: CallState.Loaded})),

  on(UpdateAuthDataActions.updateMPEmail, (state, action) =>
    ({...state, authData: {...state.authData, data: {...state.authData.data, mpAuthResponse: {
      ...state.authData.data.mpAuthResponse, email: action.email || state.authData.data.mpAuthResponse.email}
    }}})
  ),

  on(CheckTokenActions.checkTokenValidation, (state) => ({...state, tokenValidation: {data: null, callState: CallState.Loading, error: null}})),
  on(CheckTokenActions.checkTokenValidationSuccess, (state, action) => ({...state, tokenValidation: {data: action.user, callState: CallState.Loaded, error: null}})),
  on(CheckTokenActions.checkTokenValidationFailure, (state, action) => ({...state, tokenValidation: {data: null, callState: CallState.Error, error: action.error}})),

  on(ResetPasswordActions.resetPassword, (state) => ({...state, resetPassword: {data: null, callState: CallState.Loading, error: null}})),
  on(ResetPasswordActions.resetPasswordSuccess, (state) => ({...state, resetPassword: {data: null, callState: CallState.Loaded, error: null}})),
  on(ResetPasswordActions.resetPasswordFailure, (state, action) => ({...state, resetPassword: {data: null, callState: CallState.Error, error: action.error}})),

  on(SetupAccountActions.setupAccount, (state) => ({...state, setupAccount: {data: null, callState: CallState.Loading, error: null}})),
  on(SetupAccountActions.setupAccountSuccess, (state) => ({...state, setupAccount: {data: null, callState: CallState.Loaded, error: null}})),
  on(SetupAccountActions.setupAccountFailure, (state, action) => ({...state, setupAccount: {data: null, callState: CallState.Error, error: action.error}})),

  on(SetupAccountActions.resendSetupAccount, (state) => ({...state, resendSetupAccount: {data: null, callState: CallState.Loading, error: null}})),
  on(SetupAccountActions.resendSetupAccountSuccess, (state) => ({...state, resendSetupAccount: {data: null, callState: CallState.Loaded, error: null}})),
  on(SetupAccountActions.resendSetupAccountFailure, (state, action) => ({...state, resendSetupAccount: {data: null, callState: CallState.Error, error: action.error}})),

  on(SignupAccountActions.signupAccount, (state) => ({...state, signupAccount: {data: null, callState: CallState.Loading, error: null}})),
  on(SignupAccountActions.signupAccountSuccess, (state) => ({...state, signupAccount: {data: null, callState: CallState.Loaded, error: null}})),
  on(SignupAccountActions.signupAccountFailure, (state, action) => ({...state, signupAccount: {data: null, callState: CallState.Error, error: action.error}})),
);

export const authFeature = createFeature({
  name: authFeatureKey,
  reducer,
});

