import {HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse} from '@angular/common/http';
import {asyncScheduler, Observable, of, scheduled, throwError} from 'rxjs';
import {catchError, finalize, shareReplay, tap} from 'rxjs/operators';
import {NgHttpCachingHeadersList, NgHttpCachingService} from './ng-http-caching.service';
import {inject} from '@angular/core';
import {NetworkConnectionService} from '../services/network-connection.service';

export const ngHttpCachingInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const cacheService = inject(NgHttpCachingService);
  const connectionService = inject(NetworkConnectionService);
  // run garbage collector
  cacheService.runGc();
  // Don't cache if it's not cacheable
  if (!cacheService.isCacheable(req)) {
    return sendRequest(req, next);
  }

  // Return cached copy if isCacheFirst or no network connection
  if (cacheService.isCacheFirst(req) || !connectionService.connectionState.hasNetworkConnection) {
    // Checked if there is pending response for this request
    const cachedObservable = cacheService.getFromQueue(req);
    if (cachedObservable) {
      return cachedObservable;
    }
    // Checked if there is cached response for this request
    const cachedResponse = cacheService.getFromCache(req);
    if (cachedResponse) {
      // console.log('cachedResponse');
      return scheduled(of(cachedResponse.clone()), asyncScheduler);
    }
  }

  // If the request of going through for first time
  // then let the request proceed and cache the response
  // console.log('sendRequest', req);
  const shared = sendRequest(req, next).pipe(
    tap(event => {
      if (event instanceof HttpResponse) {
        cacheService.addToCache(req, event.clone());
      }
    }),
    catchError(error => {

      // if it's a network error then check if a cached version is available
      if (error.status === 504 || (error.status === 0 && error.error instanceof ProgressEvent)) {
        const cachedResponse = cacheService.getFromCache(req);
        if (cachedResponse) {
          return scheduled(of(cachedResponse.clone()), asyncScheduler);
        } else {
          // can handle the no cache available error
          return throwError(() => new Error('No Internet Connection'));
        }
      }

      return throwError(error)
    }),
    finalize(() => {
      // delete pending request
      cacheService.deleteFromQueue(req);
    }),
    shareReplay()
  );

  // add pending request to queue for cache parallel request
  cacheService.addToQueue(req, shared);
  return shared;
};

/**
 * Send http request (next handler)
 */
function sendRequest(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
  let cloned = req.clone();
  // trim custom headers before send request
  NgHttpCachingHeadersList.forEach(ngHttpCachingHeaders => {
    if (cloned.headers.has(ngHttpCachingHeaders)) {
      cloned = cloned.clone({headers: cloned.headers.delete(ngHttpCachingHeaders)});
    }
  });
  return next(cloned);
}
