import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ENV_CONFIG} from '../../environment.config';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NycidpService {
  private environmentConfig = inject(ENV_CONFIG);
  private _http = inject(HttpClient);

  exchangeToken(code: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json');
    const creds = {
      code: code,
      redirectUri: `${window.location.origin}/nycidp`
    };
    const url = 'api/NycAuth/OAuth/Tokens';
    return this._http.post(`https://reader.lightsailed.com/${url}`, JSON.stringify(creds),
      {
        headers: headers,
        observe: 'body',
        responseType: 'text' as any,
      }).pipe(
      map((res) => res),
    );
  }
}
